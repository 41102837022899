import { ImageFieldImage } from '@prismicio/client';
import { SliceClass } from 'interfaces';
import { NewLandingPageDocumentDataBodyProductDetailOverviewSlice } from 'prismic';
import { ProductListItem } from 'product-shared';

export class ProductDetailOverviewSlice extends SliceClass {
  product: ProductListItem;
  orderType: 'new';
  icon: ImageFieldImage;
  sectionId: string;

  constructor(sliceData: NewLandingPageDocumentDataBodyProductDetailOverviewSlice, product: ProductListItem) {
    super(sliceData);
    const primary = sliceData.primary;
    this.icon = primary.icon;
    this.product = product;
    if (primary.type === 'New subscription') {
      this.orderType = 'new';
    }
    this.sectionId = primary.section_id;
  }
}
