import { PrismicSlice, SliceClass } from 'interfaces';
import { Offer } from 'prismic';
import { ProductListItem } from 'product-shared';

export class OfferBoxesSlice extends SliceClass {
  constructor(
    sliceData: PrismicSlice,
    public offers: Offer<ProductListItem>[],
    public displaySize: string,
    public title?: string,
    public sectionId?: string
  ) {
    super(sliceData);
  }
}
